<template>
  <div>
    <van-list v-model="listLoading" :finished="listFinished" @load="listLoad" finished-text="没有更多了">
      <van-cell-group v-for="(item,index) in specimenList" :key="index" :title="item.specimenCode">
        <van-cell title="击实次数" :value="item.jiShiCiShu"></van-cell>
        <van-cell title="击实重量" :value="item.jiShiZhongLiang"></van-cell>
        <van-cell title="试件直径" :value="item.shiJianZhiJing"></van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      specimenList: [],
      listLoading: false,
      listFinished: false,
      currentPage: 1,
    };
  },
  methods: {
    listLoad() {
      let that = this;
      that.axios
        .post("JSY001/GetListBySerialNumber", {
          currentPage: that.currentPage,
          onlyText: that.serialNumber,
        })
        .then(function (response) {
          response.data.data.data.forEach((element) => {
            that.specimenList.push(element);
          });
          that.listLoading = false;
          if (that.specimenList.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
  },
  mounted() {},
};
</script>

<style>
</style>